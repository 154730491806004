<div class="panel">
  <app-onboarding-header [centered]="true"></app-onboarding-header>

  <!-- Initial Form -->
  @if (step === Step.Initial) {
    <div class="d-flex flex-column spacing-32">
      <app-onboarding-title [align]="'center'" title="Forgot your organisation subdomain?">
        Enter your email and we'll help you recover the associated domain, if it exists
      </app-onboarding-title>

      <form class="w-100 d-flex flex-column spacing-32" [formGroup]="form" (submit)="onSubmit()">
        <div class="form-area w-100 d-flex flex-column spacing-16">
          <app-inputs
            [placeholder]="'e.g. johng@example.com'"
            formControlName="email"
            Label="E-Mail"></app-inputs>
        </div>
        <div class="d-flex flex-column spacing-32">
          <app-buttons
            [type]="'submit'"
            [active]="false"
            class="app-buttons w-100"
            [disabled]="form.invalid"
            label="Lookup my organisation"
            [IconType]="loaderButton ? 'loader' : 'import'"
            size="large"
            [Type]="loaderButton ? 'onlyIcon' : 'withoutIcon'"
            [shortcutKey]="false"></app-buttons>
          <app-link-btn
            (custom_click)="goBack()"
            class="align-items-center justify-content-center w-100 d-md-flex"
            label="Back to Sign In"
            [active]="false"
            size="medium"
            IconType="refund"
            [disabled]="false"></app-link-btn>
        </div>
      </form>
    </div>
  }

  <!-- Email Sent -->
  @if (step === Step.Sent) {
    <div class="d-flex flex-column spacing-24">
      <app-onboarding-title title="Check your email" [align]="'center'">
        Please check your inbox for the domain to log back into your account
      </app-onboarding-title>

      <div class="actions w-100">
        <app-secondary-btns
          class="w-100"
          [active]="false"
          [disabled]="false"
          label="Check my inbox"
          (onClick)="onCheckInbox()"
          size="large"
          Type="leftIcon"
          IconValue="⌘N"
          IconType="google"></app-secondary-btns>
      </div>

      <div class="info-toast d-flex align-items-center spacing-12 justify-content-center w-100">
        <app-ce-icon [name]="'info'" [svg_class]="['d-flex', 'primary-50']"></app-ce-icon>
        <div *ngIf="timer.count === 0" class="h4-reg primary-60">Didn’t receive the email?</div>
        <div *ngIf="timer.count > 0" class="h4-reg primary-60">Email sent again!</div>
        <div *ngIf="timer.count > 0 && !timer.blocked" class="h4-reg primary-60">
          Still did not receive it?
        </div>
        <div *ngIf="timer.count > 0 && timer.blocked" class="h4-reg primary-60">
          If you still have not received it, try again after
          <span class="live-count primary-100 h3-bold">{{ timer.countdown }} seconds</span>
        </div>
        @if (!timer.blocked) {
          <app-link-btn
            (click)="resend()"
            label="Resend"
            [active]="false"
            size="medium"
            IconType="arrow_export"
            [disabled]="false"></app-link-btn>
        }
      </div>
      <app-link-btn
        (custom_click)="goBack()"
        class="align-items-center justify-content-center w-100 d-md-flex"
        label="Back to Sign In"
        [active]="false"
        size="medium"
        IconType="refund"
        [disabled]="false"></app-link-btn>
    </div>
  }
</div>
