import { Injectable } from '@angular/core';
import { PreloadAllModules, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProtectionService } from '@services/core-services/protection.service';
import { UrlService } from '@services/auth-services/url.service';

@Injectable({ providedIn: 'root' })
export class MainRoutesPreloadingStrategy implements PreloadAllModules {
  constructor(
    private protectionService: ProtectionService,
    private urlService: UrlService
  ) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // Only preload main routes if setup is already done and we have a valid subdomain
    if (route.path === '' || route.path === ':store_slug/:app_mode') {
      const { subdomain } = this.urlService.getUrlSettings();
      if (this.protectionService.isSetupAlready() && subdomain) {
        return load();
      }
      return of(null);
    }

    // For other routes, preload based on authentication state
    if (this.protectionService.isSetupAlready()) {
      return load();
    }
    return of(null);
  }
}
