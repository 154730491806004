import { ApplicationMode, ThemeMode } from '@services/core-services/app.service';

export const COMMANDS = [
  { name: 'Refresh Index', icon: 'refresh', key: 'R', index: 'RefreshIndex' },
  { name: 'Change Theme', icon: 'jam_computer_alt', key: 'T', index: 'Theme' },
  { name: 'Switch Mode', icon: 'convert_dark', key: 'M', index: 'Mode' },
  { name: 'Logout', icon: 'convert_dark', key: 'L', index: 'Logout' },
];
export const THEME = [
  { name: 'Change Theme to Light', icon: 'light', index: ThemeMode.Light },
  { name: 'Change Theme to Dark', icon: 'dark_theme', index: ThemeMode.Dark },
];
export const REFRESHINDEX = [
  { name: 'Refresh Index for Customers', icon: 'person', index: 'Customers' },
  { name: 'Refresh Index for Orders', icon: 'cart', index: 'Orders' },
  { name: 'Refresh Index for Product', icon: 'box', index: 'Products' },
];
export const MODE = [
  { name: 'Live Mode', icon: 'convert_dark', index: ApplicationMode.Live },
  { name: 'Staging Mode', icon: 'convert_dark', index: ApplicationMode.Sandbox },
];
