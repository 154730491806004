<div class="panel">
  <app-onboarding-header>
    <span class="h3-reg primary-60">Don’t have an account?</span>
    <app-link-btn label="Sign Up" routerLink="/signup" IconType="panel_arrow_right"></app-link-btn>
  </app-onboarding-header>
  <div class="d-flex flex-column spacing-40">
    <app-onboarding-title title="Login to your account">
      Welcome! Please enter your organization's unique subdomain to access your account and get
      started.
    </app-onboarding-title>

    <form class="w-100 d-flex flex-column spacing-32" [formGroup]="loginForm" (submit)="onSubmit()">
      <div class="form-area w-100 d-flex flex-column spacing-16">
        <app-inputs
          [form]="loginForm"
          [placeholder]="'enter subdomain'"
          [name]="'subdomain'"
          Label="Subdomain"
          toggle=""
          [warn]="false"
          [suffix]="true"
          [suffixBadge]="suffixBadge"
          [state]="subdomain_state"
          [error]="subdomain_error"
          (focusout)="setSubdomainError()"></app-inputs>
        <app-link-btn
          (custom_click)="goToForgotDomain()"
          class="align-items-end justify-content-end w-100 d-md-flex"
          label="I forgot my domain"
          [active]="false"
          size="medium"
          IconType="refund"
          [disabled]="false"></app-link-btn>
      </div>
      <div class="actions flex-column spacing-4">
        <app-buttons
          [type]="'submit'"
          [active]="false"
          class="app-buttons w-100"
          [disabled]="loginForm.invalid"
          label="Login"
          [IconType]="loaderButton ? 'loader' : 'import'"
          size="large"
          [Type]="loaderButton ? 'onlyIcon' : 'withoutIcon'"
          [shortcutKey]="false"></app-buttons>
        <app-secondary-btns
          class="mobile-block d-none w-100"
          routerLink="/"
          [active]="false"
          [disabled]="false"
          label="I don’t have an account"
          size="large"
          Type="withoutIcon"
          IconValue="⌘N"
          IconType="convert"></app-secondary-btns>
      </div>
    </form>
  </div>
</div>
