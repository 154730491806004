import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UrlService } from '@services/auth-services/url.service';

export const domainGuard: CanActivateFn = route => {
  const urlService = inject(UrlService);
  const settings = urlService.getUrlSettings();
  const whitelistedDomains = urlService.getWhitelistedTopLevelDomains();
  const currentHost = settings.host;

  // Only check if the domain is whitelisted
  if (!currentHost || !whitelistedDomains.includes(currentHost)) {
    // If not whitelisted, redirect to the base domain
    urlService.redirectToBasePath('signin');
    return false;
  }

  return true;
};
