<div class="raycast">
  <cmdk-command [filter]="filterIndex">
    <div class="cmdk-top-badge">
      @for (p of pages; track p) {
        <div class="cmdk-badge">
          {{ p }}
        </div>
      }
      @if (this.activeIndex) {
        <div class="cmdk-badge">
          {{ this.activeIndex }}
        </div>
      }
    </div>
    <input
      cmdkInput
      autofocus
      placeholder="Search for {{ this.activeIndex ? this.activeIndex : 'app and commands' }}"
      (input)="setSearch($event)"
      class="h4-reg" />
    @if (!searchData && !searchResults) {
      <div *cmdkEmpty>No results found.</div>
    }
    <cmdk-list class="cmdk-list-active custom-scroll">
      <cmdk-group *ngIf="suggestion.length > 0 && !this.activeIndex">
        <div class="results">
          @for (items of suggestion; track items; let i = $index) {
            <button
              cmdkItem
              [value]="items.name"
              class="suggestion-grid"
              (click)="itemClick(items)"
              (selected)="itemClick(items)">
              <div class="d-flex align-items-center justify-content-between w-100">
                <h4 class="h3-reg primary-60 suggestion-name d-flex align-items-center spacing-8">
                  <app-ce-icon [name]="items.icon" [svg_class]="['primary-50']"></app-ce-icon>
                  {{ items.name }}
                </h4>
                <span class="shortcut">
                  <span class="lable_name h4-reg text-nowrap primary-60">⇧</span>
                  <span class="lable_name h4-reg text-nowrap primary-60">{{ items.key }}</span>
                </span>
              </div>
            </button>
          }
        </div>
      </cmdk-group>

      <cmdk-group *ngIf="commands.length > 0">
        <div class="results">
          @if (this.activeIndex !== 'Theme' && this.activeIndex !== 'Mode' && !this.activeIndex) {
            @for (command of commands; track command; let i = $index) {
              <button
                cmdkItem
                [value]="command.name"
                class="suggestion-grid"
                (click)="itemClick(command)"
                (selected)="itemClick(command)">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <h4 class="h3-reg primary-60 suggestion-name d-flex align-items-center spacing-8">
                    <app-ce-icon [name]="command.icon" [svg_class]="['primary-50']"></app-ce-icon>
                    {{ command.name }}
                  </h4>
                  @if (command.key) {
                    <span class="shortcut">
                      <span class="lable_name h4-reg text-nowrap primary-60">⇧</span>
                      <span class="lable_name h4-reg text-nowrap primary-60">{{
                        command.key
                      }}</span>
                    </span>
                  }
                </div>
              </button>
            }
          }
          @if (this.activeIndex === 'RefreshIndex') {
            @for (command of refreshIndexData; track command; let i = $index) {
              <button
                cmdkItem
                [value]="command.name"
                class="suggestion-grid"
                (selected)="refreshIndex(command)">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <h4 class="h3-reg primary-60 suggestion-name d-flex align-items-center spacing-8">
                    <app-ce-icon [name]="command.icon" [svg_class]="['primary-50']"></app-ce-icon>
                    {{ command.name }}
                  </h4>
                </div>
              </button>
            }
          }
          @if (this.activeIndex === 'Theme') {
            @for (command of themes; track command; let i = $index) {
              <button
                cmdkItem
                [value]="command.name"
                class="suggestion-grid"
                (click)="changeTheme(command)"
                (selected)="changeTheme(command)">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <h4 class="h3-reg primary-60 suggestion-name d-flex align-items-center spacing-8">
                    <app-ce-icon [name]="command.icon" [svg_class]="['primary-50']"></app-ce-icon>
                    {{ command.name }}
                  </h4>
                </div>
              </button>
            }
          }
          @if (this.activeIndex === 'Mode') {
            @for (command of modes; track command; let i = $index) {
              <button
                cmdkItem
                [value]="command.name"
                class="suggestion-grid"
                (click)="changeMode(command)"
                (selected)="changeMode(command)">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <h4 class="h3-reg primary-60 suggestion-name d-flex align-items-center spacing-8">
                    <app-ce-icon [name]="command.icon" [svg_class]="['primary-50']"></app-ce-icon>
                    {{ command.name }}
                  </h4>
                </div>
              </button>
            }
          }
        </div>
      </cmdk-group>

      <cmdk-group *ngIf="navigations.length > 0">
        <div class="results">
          @for (menu of navigations; track menu; let i = $index) {
            <button
              cmdkItem
              [value]="menu.name"
              class="suggestion-grid"
              (click)="menuClick(menu)"
              (selected)="menuClick(menu)">
              <div class="d-flex align-items-center justify-content-between w-100">
                <h4 class="h3-reg primary-60 suggestion-name d-flex align-items-center spacing-8">
                  <app-ce-icon [name]="menu.icon" [svg_class]="['primary-50']"></app-ce-icon>
                  {{ menu.name }}
                </h4>
                <div class="indication h4-reg primary-60 d-flex align-items-center spacing-8">
                  <app-ce-icon
                    [name]="'arrow_right'"
                    class="arrow-icon"
                    [svg_class]="['primary-100']"></app-ce-icon>
                  Go to {{ menu.name }}
                </div>
              </div>
            </button>
          }
        </div>
      </cmdk-group>

      @for (results of searchResults; track results; let j = $index) {
        <cmdk-group *ngIf="searchData">
          <div class="results">
            <div class="search-results">
              @for (item of results.hits; track item; let i = $index) {
                @if (results['indexUid'].includes('customers')) {
                  <button
                    class="grid d-flex align-items-start w-100"
                    cmdkItem
                    [value]="item.id"
                    (click)="onCustomer(item.id)"
                    (selected)="onCustomer(item.id)">
                    <div class="media d-flex spacing-8">
                      <div class="media-left">
                        <div class="avatar">
                          <app-avatar-icon
                            seed="{{ item?.full_name }}"
                            [size]="32"></app-avatar-icon>
                        </div>
                      </div>
                      <div
                        class="media-body d-flex align-items-start justify-content-between w-100">
                        <div class="d-flex flex-column align-items-start">
                          <h4 class="h3-reg m-0 d-flex align-items-center gap">
                            {{ item.full_name ? item.full_name : 'N/a' }}
                            <app-ce-icon
                              [name]="'verified'"
                              [svg_class]="['success-120']"></app-ce-icon>
                          </h4>
                        </div>
                        @if (!activeIndex) {
                          <div class="indication h4-reg primary-60">Customer</div>
                        }
                      </div>
                    </div>
                  </button>
                } @else if (results['indexUid'].includes('orders')) {
                  <button
                    class="grid spacing-16 widgets"
                    [class.cmdk-item-active]="i === 0"
                    cmdkItem
                    (click)="onOrder(item.order_number)"
                    (selected)="onOrder(item.order_number)">
                    <div class="d-flex align-items-center w-100">
                      <div class="media d-flex spacing-8">
                        <div class="media-left">
                          <div class="product-img">
                            <app-icon-container
                              Type="icon"
                              IconType="cart_icon"
                              Size="medium"
                              [background_color]="'primary-20'"></app-icon-container>
                          </div>
                        </div>
                        <div
                          class="media-body d-flex align-items-start justify-content-between w-100">
                          <div class="d-flex flex-column align-items-start">
                            <h4 class="h3-reg m-0 d-flex align-items-center gap">
                              {{ item.order_number }}
                            </h4>
                          </div>
                          @if (!activeIndex) {
                            <div class="indication h4-reg primary-60">Order</div>
                          }
                        </div>
                      </div>
                    </div>
                  </button>
                } @else if (results['indexUid'].includes('products')) {
                  <button
                    class="grid spacing-16 widgets"
                    [class.cmdk-item-active]="i === 0"
                    cmdkItem
                    (click)="onProduct(item.product_id, item.variant_id)"
                    (selected)="onProduct(item.product_id, item.variant_id)">
                    <div class="d-flex align-items-center w-100">
                      <div class="media d-flex spacing-8">
                        <div class="media-left">
                          <div class="product-img">
                            <img
                              class="w-100"
                              alt=""
                              height="137"
                              width="293"
                              [src]="
                                item.images?.length > 0
                                  ? item.images[0]?.url_thumbnail
                                  : '/assets/No-Image-Placeholder.svg'
                              " />
                          </div>
                        </div>
                        <div
                          class="media-body d-flex align-items-start justify-content-between w-100">
                          <div class="d-flex flex-column align-items-start">
                            <h4 class="h3-reg m-0 d-flex align-items-center">
                              {{ item.variant_id ? item.variant_name : item.product_name }}
                            </h4>
                          </div>
                          @if (!activeIndex) {
                            <div class="indication h4-reg primary-60">
                              {{ item.variant_id ? 'Variant' : 'Product' }}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </button>
                }
              }
            </div>
          </div>
        </cmdk-group>
      }
    </cmdk-list>
  </cmdk-command>
</div>
