import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { OnboardingLayoutComponent } from './layouts/onboarding-layout/onboarding-layout.component';
import { LoginLayoutComponent } from './login-module/login-layout/login-layout.component';
import { SignupComponent } from './onboarding-module/signup/signup.component';
import {
  authActionGuard,
  hybridGuard,
  tenantAndUserGuard,
  tenantGuard,
  userGuard,
} from '@services/core-services/guards';
import { SigninComponent } from './onboarding-module/signin/signin.component';
import { RecoverSubdomainComponent } from './onboarding-module/recover-subdomain/recover-subdomain.component';
import { OrganizationLayoutComponent } from './layouts/organization-layout/organization-layout.component';
import { storeSlugGuard } from '@services/core-services/store-slug.guard';
import { orderedAsyncGuards } from '@services/core-services/utils/guards';
import { MainRoutesPreloadingStrategy } from '@services/core-services/loading-strategy/main-routes-preloading.strategy';
import { UrlService } from '@services/auth-services/url.service';
import { AppService } from '@services/core-services/app.service';
import { domainGuard } from '@services/core-services/guards/domain.guard';

const routes: Routes = [
  {
    path: 'signup',
    canActivate: [domainGuard],
    component: OnboardingLayoutComponent,
    children: [
      {
        path: '',
        component: SignupComponent,
      },
    ],
  },
  {
    path: 'signin',
    canActivate: [domainGuard],
    component: OnboardingLayoutComponent,
    children: [
      {
        path: '',
        component: SigninComponent,
      },
      {
        path: 'recover',
        component: RecoverSubdomainComponent,
      },
    ],
  },
  {
    path: 'onboarding',
    canActivate: [userGuard],
    component: OnboardingLayoutComponent,
    loadChildren: () =>
      import('src/app/onboarding-module/onboarding-module-routing.module').then(m => m.routes),
  },
  {
    path: 'organisation',
    component: OrganizationLayoutComponent,
    canActivate: [tenantAndUserGuard],
    loadChildren: () =>
      import(
        'src/app/setting-module/organization-settings/organization-settings-routing.module'
      ).then(m => m.routes),
    title: 'organisation-settings',
  },
  {
    path: 'auth',
    canActivate: [orderedAsyncGuards([authActionGuard, tenantGuard])],
    component: LoginLayoutComponent,
    loadChildren: () =>
      import('src/app/login-module/login-module-routing.module').then(m => m.routes),
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [
      orderedAsyncGuards([
        () => {
          const urlService = inject(UrlService);
          const router = inject(Router);
          const settings = urlService.getUrlSettings();

          if (
            settings.host &&
            (urlService.getWhitelistedTopLevelDomains().includes(settings.host) ||
              !urlService.getSubdomain())
          ) {
            return router.navigate(['/signin']);
          }
          return true;
        },
        hybridGuard,
        storeSlugGuard,
      ]),
    ],
    loadChildren: () => import('src/app/modules-routing.module').then(m => m.routes),
  },
  {
    path: ':store_slug/:app_mode',
    resolve: {
      validateAppMode: (route: { params: { [key: string]: string } }) => {
        const appService = inject(AppService);
        const router = inject(Router);
        const validMode = appService.getApplicationModePrefix();
        const currentMode = route.params['app_mode'];
        const storeSlug = route.params['store_slug'];

        if (currentMode !== validMode) {
          router.navigate([storeSlug, validMode]);
          return false;
        }
        return true;
      },
    },
    component: MainLayoutComponent,
    canActivate: [hybridGuard, storeSlugGuard],
    loadChildren: () => import('src/app/modules-routing.module').then(m => m.routes),
  },
  // Remove the duplicate route at the root level
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: MainRoutesPreloadingStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
